import * as React from 'react';

// components
import { Group, MantineTheme, MediaQuery, Stack, ThemeIcon, createStyles, useMantineTheme, Grid, Center } from '@mantine/core';
import TextSection from '../sections/text-section';
import { TbCode, TbUsers } from 'react-icons/tb';
import FeatureSection from '../sections/feature-section';
import H2 from '../typography/h2';
import TextDivider from '../ui/text-divider';

// illustrations
import codingSVG from '../../images/illustrations/coding.svg';
import codingDualSetupSVG from '../../images/illustrations/coding-dual-setup.svg';
import VectorSVG from '../../images/background-images/Vector.svg'

const useStyles = createStyles((theme: MantineTheme) => ({
    root: {
        marginTop: -60,
        paddingTop: 38,
        paddingBottom: 88,
        backgroundImage: `url(${VectorSVG})`,
        backgroundPositionY: 'top',
        backgroundPositionX: 'left',
        backgroundRepeat: 'no-repeat',
        [theme.fn.smallerThan('md')]: {
            backgroundImage: 'none'
        }
    },

    title: {
        marginTop: 100,
        marginBottom: 'auto',
        [theme.fn.smallerThan('md')]: {
            maxHeight: 768,
            fontSize: "calc(38px + 1vw)",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 7,
        },
    },

    textSize: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(32px + 1vw)",
            marginBottom: theme.spacing.xl,
            lineHeight: 1.3,
            textAlign: 'center',
            paddingRight: 70,
        },
    },

    imageAlign: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: 10,
        }
    },

    anchorCustomClass: {
        [theme.fn.smallerThan('md')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: theme.spacing.lg,
            paddingTop: theme.spacing.lg,
            fontSize: "calc(32px + 1vw)"
        }
    },

    customTitleClass: {
        [theme.fn.smallerThan('md')]: {
            transform: 'scale(1.3)',
        }
    }
}))


const WhatQminoDoes = () => {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    return (
        <div className={classes.root}>
            <TextSection>
                <H2 className={classes.title}>Full stack Java expertise
                </H2>
                <TextDivider color={theme.colors.pink[5]} />

                <Group noWrap position='apart' align='center'>
                    <Stack spacing={70} mt={70}
                    >
                        <FeatureSection
                            customClass={classes.customTitleClass}
                            anchorClass={classes.anchorCustomClass}
                            icon={

                                <ThemeIcon color='gray' variant='light' size={50}
                                    sx={{
                                        [theme.fn.smallerThan('md')]: {
                                            display: 'none',

                                        }
                                    }}>
                                    <TbUsers size={25} />
                                </ThemeIcon>
                            }

                            title='Software Consultancy'
                            link={{
                                label: 'Een expert aan boord',
                                url: '/diensten'
                            }}>

                            <Center className={classes.textSize}>
                                Vergroot uw capaciteit op de werkvloer met een full stack expert die proactief met u meedenkt en zich vlot integreert in uw team.
                            </Center>

                        </FeatureSection>
                        <MediaQuery largerThan={'md'} styles={{ display: 'none' }}>
                            <div>
                                <img
                                    className={classes.imageAlign}
                                    src={codingSVG}
                                    alt=''
                                    width={700} />
                            </div>
                        </MediaQuery>
                        <FeatureSection
                            customClass={classes.customTitleClass}
                            anchorClass={classes.anchorCustomClass}
                            icon={
                                <ThemeIcon color='gray' variant='light' size={50}
                                    sx={{
                                        [theme.fn.smallerThan('md')]: {
                                            display: 'none',
                                        },
                                    }}>
                                    <TbCode size={25} />
                                </ThemeIcon>
                            }
                            title='Webapplicaties op maat'
                            link={{
                                label: 'Maatwerk van A tot Z',
                                url: '/diensten'
                            }}>
                            <Center className={classes.textSize}>
                                Vertrouw onze software factory de volledige ontwikkeling
                                van uw webapplicatie toe en reken op agile maatwerk
                                van analyse tot nazorg.
                            </Center>
                        </FeatureSection>
                    </Stack>
                    <MediaQuery query="(max-width: 768px)" //add mantine media query
                        smallerThan={'md'} styles={{
                            display: 'none'
                        }}>

                        <img src={codingDualSetupSVG} alt='' />

                    </MediaQuery>
                </Group>
            </TextSection>
        </div>
    );
};

export default WhatQminoDoes;
