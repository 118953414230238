import * as React from 'react';
import type { PropsWithChildren } from 'react';
import { Title } from '@mantine/core';
import {TitleProps} from "@mantine/core/lib/Title/Title";

interface Props extends TitleProps {};

const H2 = (props: Props) => {
    return (
        <Title
            order={2}
            mb={25}
            sx={(theme) => ({
                fontSize: 36,

                [theme.fn.smallerThan('sm')]: {
                    fontSize: 28,
                    marginBottom: 20,
                },
            })}
            {...props}/>
    );
};

export default H2;
