import * as React from 'react';
import { createStyles, MantineColor, MantineTheme } from '@mantine/core';

interface Props {
    propClass?: any,
    color?: MantineColor;
}

const TextDivider = ({ color }: Props) => {
    const WIDTH = 38;
    const HEIGHT = 8;
    const RADIUS = 4;

    const useStyles = createStyles((theme: MantineTheme) => ({
        divider: {
            position: 'relative',
            backgroundColor: color || theme.colors.brand[5],
            width: WIDTH,
            height: HEIGHT,
            borderRadius: RADIUS,

            '&:before': {
                content: '""',
                position: 'absolute',
                left: 15,
                width: WIDTH,
                height: HEIGHT,
                borderRadius: RADIUS,
                backgroundColor: color || theme.colors.brand[5],
                opacity: 0.5,

            },
            '&:after': {
                content: '""',
                position: 'absolute',
                left: 30,
                width: WIDTH,
                height: HEIGHT,
                borderRadius: RADIUS,
                backgroundColor: color || theme.colors.brand[5],
                opacity: 0.3,

            },
            // ALIGN TEXTDIVIDER CENTER IN SMALLER SCREENS
            [theme.fn.smallerThan('md')]:{
                display: 'flex',
                marginLeft:'auto',
                marginRight:'auto',
                alignSelf:'center'
            }
        },
    }));

    const { classes } = useStyles();

    return (
        <div className={classes.divider} />
    );
};

export default TextDivider;