import { graphql, useStaticQuery } from 'gatsby';
import type { ClientType, QuoteType } from '../../types';

type GraphQLResult = {
    allContentfulClient: {
        nodes: ClientType[];
    };
};

export const getAllClientQuotes = (): QuoteType[] => {
    const data = useStaticQuery<GraphQLResult>(graphql`
        query allQuotes {
            allContentfulClient {
                nodes {
                    quote {
                        id
                        author
                        authorDescription {
                            raw
                        }
                        content {
                            raw
                        }
                    }
                }
            }
        }
    `);

    return data.allContentfulClient.nodes
        // Map to list of quotes
        .map(client => client.quote)
        // Filter out undefined quotes
        .filter(quote => quote) as QuoteType[];
};