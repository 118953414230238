import * as React from 'react';
import type { PropsWithChildren } from 'react';
import { Title, TitleProps } from '@mantine/core';

// to implement class on H3 tag
interface Props extends TitleProps, PropsWithChildren {};

const H3 = (props: Props) => {
    return (
        <Title
            order={3}
            sx={(theme) => ({
                fontSize: 24,

                [theme.fn.smallerThan('sm')]: {
                    fontSize: 20,
                },
            })}
            {...props}/>
    );
};

export default H3;