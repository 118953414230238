import { Button, Container, createStyles, Grid, Group, MantineTheme, SimpleGrid, Stack, Text, useMantineTheme } from '@mantine/core';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { TbSend } from 'react-icons/tb';
import H3 from '../typography/h3';

// logos
import springLogo from '../../images/tech/java-spring.svg';
import hibernateLogo from '../../images/tech/hibernate.svg';
import javaLogo from '../../images/tech/java-logo.svg';
import nodeJSLogo from '../../images/tech/node-js.svg';
import reactLogo from '../../images/tech/react.svg';
import angularLogo from '../../images/tech/angular.svg';

const useStyles = createStyles((theme: MantineTheme) => ({
    root: {
        backgroundColor: theme.colors.gray[1],
        paddingTop: 70,
        paddingBottom: 70,

    },

    title: {
        [theme.fn.smallerThan('md')]: {
            paddingBottom: 50,
            alignSelf: 'center',
            fontSize: "calc(32px + 1vw)",
        },
    },

    container: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },

    textView: {
        [theme.fn.smallerThan('md')]: {
            lineHeight: 1.3,
            textAlign: 'center',
            paddingTop: 40,
            fontSize: "calc(32px + 1vw)",
            width: '80vw',
        },
    },

    techStack: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        }
    },

    techStackTwo: {
        [theme.fn.largerThan('md')]: {
            display: 'none'
        }
    },

    contactButton: {
        [theme.fn.smallerThan('md')]: {
            fontSize:"calc(28px + 1vw)"
        }
    }
}));

const TechStack = () => {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    return (
        <div className={classes.root}>
            <Container size='lg' sx={{ maxWidth: '1245px', }}>
                <Group spacing={120} grow
                >
                    <div className={classes.container}>
                        <H3 w='max-content' className={classes.title}>De juiste technologie voor uw project</H3>

                        <Stack spacing={60} w={700} className={classes.techStackTwo}>
                            <Group position="apart">
                                <Stack align='center' spacing={20}>
                                    <img src={springLogo} alt='Java Spring' />
                                    <Text size={16} color='gray.6'>Java Spring</Text>
                                </Stack>
                                <Stack align='center' spacing={20}>
                                    <img src={hibernateLogo} alt='Hibernate' />
                                    <Text size={16} color='gray.6'>Hibernate</Text>
                                </Stack>
                                <Stack align='center' spacing={20}>
                                    <img src={javaLogo} alt='Java' />
                                    <Text size={16} color='gray.6'>Java</Text>
                                </Stack>
                            </Group>
                            <Group position="apart">
                                <Stack align='center' spacing={20}>
                                    <img src={nodeJSLogo} alt='Node.js' />
                                    <Text size={16} color='gray.6'>Node.js</Text>
                                </Stack>
                                <Stack align='center' spacing={20}>
                                    <img src={reactLogo} alt='React' />
                                    <Text size={16} color='gray.6'>React</Text>
                                </Stack>
                                <Stack align='center' spacing={20}>
                                    <img src={angularLogo} alt='Angular' />
                                    <Text size={16} color='gray.6'>Angular</Text>
                                </Stack>
                            </Group>
                        </Stack>


                        <Text mt={25} mb={40} className={classes.textView}>
                            Onze experten zijn full stack opgeleid in Java en JavaScript.
                            Programmeertalen die garant staan voor de stabiliteit,
                            schaalbaarheid en flexibiliteit van uw applicatie.
                            Uw project wordt steeds gebouwd vanuit de meest doeltreffende
                            technologie binnen dit domein.
                            <br /><br />
                            Project idee?
                        </Text>

                        <Button
                            className={classes.contactButton}
                            component='a'
                            href='mailto:hello@qmino.com'
                            variant='light'
                            color='pink'>
                            <Group spacing={15}>
                                <TbSend />
                                Contacteer ons
                            </Group>
                        </Button>
                    </div>

                    <Stack spacing={60} className={classes.techStack}>
                        <Group position="apart">
                            <Stack align='center' spacing={20}>
                                <img src={springLogo} alt='Java Spring' />
                                <Text size={16} color='gray.6'>Java Spring</Text>
                            </Stack>
                            <Stack align='center' spacing={20}>
                                <img src={hibernateLogo} alt='Hibernate' />
                                <Text size={16} color='gray.6'>Hibernate</Text>
                            </Stack>
                            <Stack align='center' spacing={20}>
                                <img src={javaLogo} alt='Java' />
                                <Text size={16} color='gray.6'>Java</Text>
                            </Stack>
                        </Group>
                        <Group position="apart">
                            <Stack align='center' spacing={20}>
                                <img src={nodeJSLogo} alt='Node.js' />
                                <Text size={16} color='gray.6'>Node.js</Text>
                            </Stack>
                            <Stack align='center' spacing={20}>
                                <img src={reactLogo} alt='React' />
                                <Text size={16} color='gray.6'>React</Text>
                            </Stack>
                            <Stack align='center' spacing={20}>
                                <img src={angularLogo} alt='Angular' />
                                <Text size={16} color='gray.6'>Angular</Text>
                            </Stack>
                        </Group>
                    </Stack>
                </Group>
            </Container>
        </div>
    );
};

export default TechStack;
