import { graphql, useStaticQuery } from 'gatsby';
import type { ClientType } from '../../types';

type GraphQLResult = {
	allContentfulClient: {
		nodes: ClientType[];
	};
};

export const getFeaturedClients = (): ClientType[] => {
    const data = useStaticQuery<GraphQLResult>(graphql`
        query featuredClients {
            allContentfulClient(filter: {featured: {eq: true}}) {
                nodes {
                    id
                    name
                    logo {
                        gatsbyImageData(
                            width: 200,
                        )
                    }
                }
            }
        }
    `);

    //TODO: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#transformoptions
    
    return data.allContentfulClient.nodes;
};