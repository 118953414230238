import * as React from 'react';

// components
import { clsx, createStyles, Group, MantineTheme, SimpleGrid, Stack, Text, ThemeIcon, useMantineTheme } from '@mantine/core';
import TextSection from '../sections/text-section';
import FeatureSection from '../sections/feature-section';
import { TbHeart, TbSettings, TbShieldCheck, TbTarget } from 'react-icons/tb';
import H2 from '../typography/h2';
import TextDivider from '../ui/text-divider';
//images
import Rectangle12SVG from '../../images/background-images/Rectangle12.svg'
import VectorSVG from '../../images/background-images/Vector.svg'

const useStyles = createStyles((theme: MantineTheme) => ({
    root: {
        backgroundColor: theme.colors.gray[1],
        backgroundImage: `url(${VectorSVG}),url(${Rectangle12SVG})`,
        backgroundPosition: 'top right,center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 20%, 100% 100%',
        [theme.fn.smallerThan('md')]: {
            backgroundImage: 'none'
        },
    },

    title: {
        fontSize: 64,
        lineHeight: '115%',


        [theme.fn.smallerThan('md')]: {
            maxHeight: 768,
            fontSize: "calc(40px + 1vw)",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 7,
        },
    },

    textSize: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(32px + 1vw)",
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop:'auto',
            marginBottom:60,
            paddingRight: 70,
            paddingBottom: theme.spacing.xl,
        },
    },

    groupCustomClass: {
        [theme.fn.smallerThan('md')]: {
            padding: theme.spacing.xl,
            transform: 'scale(1.3)',
        }
    },

    icon:{
        [theme.fn.smallerThan('lg')]:{
            transform: 'scale(1.1)',
        },
        [theme.fn.smallerThan('md')]:{
            transform: 'scale(1.5)',
        }
    },

    groupSpacing: {
        marginTop:70,
        [theme.fn.smallerThan('md')]:{
            marginTop: -20
        },
        [theme.fn.largerThan('md')]:{
            flexWrap:'nowrap'
        }
    }
}));

const CareerValues = () => {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    return (
        <div className={classes.root}>
            <TextSection>
                <H2 className={classes.title}>Wat ons drijft
                </H2>
                <TextDivider color={theme.colors.pink[5]} />

                <Stack mt={80} align='stretch'>
                    <Group  mb={25} position='apart' align='center' className={classes.groupSpacing}>
                        <FeatureSection
                            customClass={classes.groupCustomClass}
                            icon={
                                <ThemeIcon color='pink' size={50} className={classes.icon}>
                                    <TbTarget size={25} />
                                </ThemeIcon>
                            }
                            title='Expertise'>
                            <Text className={classes.textSize}>
                                We zetten in op doorgedreven technische kennis
                                en hebben de kunde om deze optimaal toe te passen
                                binnen uw project. Voor minder gaan we niet.
                            </Text>
                        </FeatureSection>

                        <FeatureSection
                            customClass={classes.groupCustomClass}
                            icon={
                                <ThemeIcon color='pink' size={50} className={classes.icon}>
                                    <TbSettings size={25} />
                                </ThemeIcon>
                            }
                            title='Efficiëntie'>
                            <Text className={classes.textSize}>
                                If it's worth doing, it's worth doing right.
                                Bij het ontwikkelen kiezen we voor relevante,
                                snelle tools met een echte meerwaarde voor uw project.
                            </Text>
                        </FeatureSection>
                    </Group>

                    <Group mb={70} pb={30} position='apart' className={classes.groupSpacing}>
                        <FeatureSection
                            customClass={classes.groupCustomClass}
                            icon={
                                <ThemeIcon color='pink' size={50} className={classes.icon}>
                                    <TbHeart size={25} />
                                </ThemeIcon>
                            }
                            title='Passie'>
                            <Text className={classes.textSize}>
                                Wat je doet vanuit je hart, doe je beter.
                                Onze passie voor ontwikkelen houden we brandend in een
                                stimulerende leeromgeving met focus op de juiste match
                                tussen consultant en project.
                            </Text>
                        </FeatureSection>

                        <FeatureSection
                            customClass={classes.groupCustomClass}
                            icon={
                                <ThemeIcon color='pink' size={50} className={classes.icon}>
                                    <TbShieldCheck size={25} />
                                </ThemeIcon>
                            }
                            title='Vertrouwen'>
                            <Text className={classes.textSize}>
                                We zeggen wat we doen en doen wat we zeggen. Binnen onze
                                vlakke structuur vormt wederzijds vertrouwen de basis
                                voor een goede teamgeest en partnerrelatie met u als klant.
                            </Text>
                        </FeatureSection>
                    </Group>
                </Stack>
            </TextSection>
        </div>
    );

};

export default CareerValues;