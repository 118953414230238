import * as React from 'react';

// types
import type { PropsWithChildren } from 'react';

// components
import { Container } from '@mantine/core';

const TextSection = ({ children }: PropsWithChildren) => {
    return (
        <Container size='lg' py={100} sx={(theme) => ({
            maxWidth: '1245px',

            [theme.fn.smallerThan('sm')]: {
                paddingTop: 40,
                paddingBottom: 40,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: '3245px'
            },
        })}>
            {children}
        </Container>
    );
};

export default TextSection;