import * as React from 'react';

// components
import { Anchor, MantineTheme, MediaQuery, Stack, Text, createStyles, useMantineTheme } from '@mantine/core';
import { Link } from 'gatsby';

// illustrations
import greatIdeaSVG from "../../images/illustrations/great-idea.svg";
// images
import VectorSVG from '../../images/background-images/Vector.svg'
const useStyles = createStyles((theme: MantineTheme) => ({
    dots:{
        marginTop: -60,
        paddingTop: 38,
        paddingBottom: 88,
        backgroundImage: `url(${VectorSVG})`,
        backgroundPositionY: 'top',
        backgroundPositionX: 'left',
        backgroundRepeat: 'no-repeat',
        [theme.fn.smallerThan('md')]: {
            backgroundImage: 'none'
        },
    },

    textSize: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(28px + 1vw)",
            lineHeight: 1.3,
            textAlign: 'center',
            alignSelf: 'center',
            paddingBottom: theme.spacing.lg,
        },
    },

    imgMargin: {
        marginTop: 90

    },
}))
const VacanciesBanner = () => {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    return (
        <div className={classes.dots}>
            <Stack spacing={30} align='center'>
                <MediaQuery smallerThan={'md'} styles={{ display: 'none' }}>
                    <img
                        className={classes.imgMargin}
                        src={greatIdeaSVG}
                        alt=''
                        width={185}/>
                </MediaQuery>
                <MediaQuery largerThan={'md'} styles={{ display: 'none'}}>
                    <img
                        className={classes.imgMargin}
                        src={greatIdeaSVG}
                        alt=''
                        width={485}/>
                </MediaQuery>

                <Stack spacing={15} align='center'>
                    <Text
                        size={24}
                        weight={700}
                        color='gray.8'
                        className={classes.textSize}>
                        Steengoed softwaretalent?
                    </Text>
                    <Anchor
                        component={Link}
                        to='/jobs'
                        size={24}
                        weight={700}
                        color='brand'
                        className={classes.textSize}
                        sx={{
                            position: 'relative',
                            marginLeft: -30,

                            '&:after': {
                                content: '">"',
                                position: 'absolute',
                                top: 0,
                                right: -30,
                                transition: 'all 0.2s ease-out',
                            },

                            '&:hover': {
                                '&:after': {
                                    right: -40,
                                },
                            },
                        }}>
                        Ontdek onze vacatures
                    </Anchor>
                </Stack>
            </Stack>
        </div>
    );
};

export default VacanciesBanner;
