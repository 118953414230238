import * as React from 'react';

// types
import type { PropsWithChildren } from 'react';

// components
import { Anchor, BackgroundImage, Button, Container, createStyles, Grid, Group, MantineTheme, MediaQuery, Stack, Text, ThemeIcon, Title, useMantineTheme } from '@mantine/core';
import { Link } from 'gatsby';
import { TbMail, TbMapPin, TbPhone, TbSend } from 'react-icons/tb';
import TextDivider from '../ui/text-divider';

// illustrations
import developmentSVG from '../../images/illustrations/development.svg';
import settingsAndDataSVG from '../../images/illustrations/settings-and-data.svg';
import VectorSVG from '../../images/background-images/Vector.svg'
import Rectangle12SVG from '../../images/background-images/Rectangle12.svg'

{/* add mantine styling instead of using inline css(sx)
  which makes the code look untidy */}
const useStyles = createStyles((theme: MantineTheme) => ({

    [theme.fn.smallerThan('md')]: {
        container: {
            alignItems: 'center',
        },
        title: {
            marginTop: 40,
            fontSize: 64,
            lineHeight: 1,
        }
    },
    [theme.fn.largerThan('md')]: {
        title: {
            marginTop: 100,
            fontSize: 64,
            lineHeight: '115%',
        },
    },

    root: {
        backgroundColor: theme.colors.gray[0],
        marginTop: 0,
        paddingTop: 88,
        paddingBottom: 88,
        backgroundImage: `url(${VectorSVG}),url(${VectorSVG}),url(${Rectangle12SVG})`,
        backgroundPositionY: 'top , top , bottom',
        backgroundPositionX: 'right,left',
        backgroundRepeat: 'no-repeat', // Prevent background image from repeating

        [theme.fn.smallerThan('md')]: {
            backgroundColor: theme.colors.gray[0],
            paddingBottom: 40,
            textAlign: 'center',
            BackgroundImage: 'none'
        }
    },

    container: {
        maxWidth: 1245,
        [theme.fn.smallerThan('sm')]: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    title: {
        marginTop: 100,
        fontSize: 64,
        lineHeight: '115%',

        [theme.fn.smallerThan('md')]: {
            marginTop: 40,
            fontSize: "calc(64px + 1vw)",
            lineHeight: 1,
        },
    },

    alignContent: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center'
        }
    },

    /* button alignment */
    contactButton: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            paddingTop: theme.spacing.xl,
            marginTop: 50,
            marginBottom: 40,
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: "calc(32px + 1vw)"
        },
    },

    /* Setting Alignment for smaller screens*/
    subTitle: {
        fontSize: 24,
        color: theme.colors.gray[7],
        marginTop: 45,
        marginBottom: 80,
        lineHeight: '130%',
        maxWidth: 455,

        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            fontSize: "calc(40px + 1vw)",
            alignSelf: 'center',
            maxWidth: '80%'
        }
    },
    contactInfoGroup: {
        [theme.fn.smallerThan('md')]: {
            maxHeight: 768,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 7,
            fontSize: "calc(18px + 1vw)"
        }
    },

    contactInfoText: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(28px + 1vw)",
            lineHeight:1.2
        }
    },
    /* Setting Alignment for smaller screens*/

    icon:{
        [theme.fn.smallerThan('md')]:{
            transform: 'scale(1.7)',
        }
    },

    topIcon:{
        marginTop: 20,
        width: 400,
        [theme.fn.smallerThan('md')]:{
            width: 700,
        }
    },
    secondaryCTA: {
        position: 'relative',
        color: theme.colors.brand[7],
        fontSize: 22,
        fontWeight: 500,

        '&:after': {
            content: '">"',
            position: 'absolute',
            top: 0,
            right: -30,
            transition: 'all 0.2s ease-out',
        },

        '&:hover': {
            '&:after': {
                right: -40,
            },
        },

        [theme.fn.smallerThan('sm')]: {
            display: 'none'
        },

        [theme.fn.smallerThan('md')]: {
            maxHeight: 768,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 7,
        },
    }
}));

const HeroHome = () => {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    const ContactInfoGroup = ({ children }: PropsWithChildren) => {
        return (
            <Group
                spacing={15}
                py={10}
                align='flex-start'
                className={classes.contactInfoGroup}
                sx={{
                    [theme.fn.smallerThan('sm')]: {
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 7,
                    },
                    [theme.fn.smallerThan('md')]: {
                        fontSize: '1.3vw',
                        gap: 7,
                    }
                }}>
                {children}
            </Group>
        );
    };

    return (
        <div className={classes.root}>
            <Container size='lg' className={classes.container}>
                <MediaQuery largerThan={'md'} styles={{
                    display: 'none',
                }}>
                    <div>
                        <img
                            src={settingsAndDataSVG}
                            alt=''
                            className={classes.topIcon}
                             />
                    </div>
                </MediaQuery>
                {/* Setting Alignment for smaller screens*/}
                <Title order={1} className={classes.title}>
                    <div style={{ marginBottom: 20 }}>Experts in software engineering</div>
                    <div>Partners in business</div>
                </Title>
                <Group mb={20} spacing={120} sx={{
                    [theme.fn.smallerThan('md')]: {
                        marginBottom: 30,
                    },
                }}>
                    <Stack className={classes.alignContent}>
                        {/* Setting Alignment for smaller screens*/}
                        <Text className={classes.subTitle}>
                            Webapplicaties en software consultancy vanuit doorgedreven expertise en strategisch partnerschap.
                        </Text>
                        <Group spacing={80}>

                            <Button component='a' className={classes.contactButton} href='mailto:hello@qmino.com'>
                                <Group spacing={15} position='center'>
                                    <TbSend />
                                    Contacteer ons
                                </Group>
                            </Button>


                            <Anchor component={Link}
                                to='/diensten' className={classes.secondaryCTA}
                                sx={{
                                    [theme.fn.smallerThan('md')]: {
                                        display: 'none',

                                    }
                                }}>
                                Ontdek onze diensten
                            </Anchor>
                            {/* Setting Alignment for smaller screens*/}

                        </Group>
                    </Stack>
                    <MediaQuery smallerThan={'md'} styles={{
                        display: 'none',

                    }}>
                        {/* Setting Alignment for smaller screens*/}
                        <div>
                            <img src={developmentSVG} alt='' />
                        </div>
                    </MediaQuery>
                </Group>
                {/* Setting Alignment for smaller screens*/}
                <MediaQuery smallerThan={'md'} styles={{
                    display: 'none'
                }}>
                    <div><TextDivider /></div>
                </MediaQuery>
                <Stack
                    spacing={5}
                    mt={25}
                    sx={{
                        [theme.fn.smallerThan('md')]: {
                            fontSize: '4vw',
                        }
                        ,
                        [theme.fn.smallerThan('sm')]: {
                            marginTop: 0,
                        }
                    }}>
                    <ContactInfoGroup>
                        <ThemeIcon variant='outline' className={classes.icon}>
                            <TbMail size={25} />
                        </ThemeIcon>
                        <Text>
                            <Anchor color='gray.6' href='mailto:hello@qmino.com' className={classes.contactInfoText}>
                                hello@qmino.com
                            </Anchor>
                        </Text>
                    </ContactInfoGroup>
                    <ContactInfoGroup>
                        <ThemeIcon variant='outline' className={classes.icon}>
                            <TbPhone size={25} />
                        </ThemeIcon>
                        <Text>
                            <Anchor color='gray.6' href='tel:+32471505056' className={classes.contactInfoText}>
                                +32 (0) 471 50 50 56
                            </Anchor>
                        </Text>
                    </ContactInfoGroup>
                    <ContactInfoGroup>
                        <ThemeIcon variant='outline' className={classes.icon}>
                            <TbMapPin size={25} />
                        </ThemeIcon>
                        <Text>
                            <Anchor color='gray.6' href='mailto:hello@qmino.com' className={classes.contactInfoText}>
                                Nieuwstraat 28
                                <br />
                                3360 Korbeek-Lo, België
                            </Anchor>
                        </Text>
                    </ContactInfoGroup>
                </Stack>
            </Container>
        </div>
    );
};

export default HeroHome;
