import * as React from 'react';
import { Text, TextProps } from '@mantine/core';

interface Props extends TextProps {};

const Quote = (props: Props) => {
    return (
        <Text
            size={20}
            color='gray.6'
            sx={{
                fontStyle: 'italic',
                lineHeight: '32px',
                b: {
                    fontWeight: 800,
                }
            }}
            {...props}/>
    );
};

export default Quote;