import * as React from 'react';

// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroHome from '../components/home-page/hero-home';
import WhatQminoDoes from '../components/home-page/what-qmino-does';
import ClientsSection from '../components/home-page/clients-section';
import CareerValues from '../components/home-page/career-values';
import TechStack from '../components/home-page/tech-stack';
import VacanciesBanner from '../components/sections/vacancies-banner';

const HomePage = () => {
    return (
        <Layout>
            <HeroHome/>
            <WhatQminoDoes/>
            <TechStack/>
            <ClientsSection/>
            <CareerValues/>
            <VacanciesBanner/>
        </Layout>
    );
};

export const Head = () => (
    <SEO/>
);

export default HomePage;
