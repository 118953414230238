import * as React from 'react';

// types
import type { PropsWithChildren, ReactNode } from 'react';

// components
import { Flex, Text, Group, Anchor, useMantineTheme } from '@mantine/core';
import { Link } from 'gatsby';
import H3 from '../typography/h3';

interface Props extends PropsWithChildren {
    customClass?:any,
    anchorClass?:any,
    icon: ReactNode;
    title: string;
    link?: {
        label: string;
        url: string;
    }
};

const FeatureSection = ({ icon, title, link, children,customClass,anchorClass }: Props) => {
    const theme = useMantineTheme();

    return (
        <Flex direction={"column"} gap={"md"}
            align={{base: 'center', sm: 'flex-start'}}
            sx={{
                maxWidth: 510,
                // breakpoint for career values
                [theme.fn.smallerThan('md')]: {
                    flexDirection: 'column',
                    alignItems:"center",
                    maxWidth:"unset",
                    marginTop:30
                },
            }}>
            <Group className={customClass} noWrap align='center' grow={false} spacing={30} sx={{
                [theme.fn.smallerThan('md')]: {
                    flexDirection: 'column',
                    alignItems:"center",
                    width:700
                },
                [theme.fn.smallerThan('sm')]: {
                    flexDirection: 'column',
                    alignItems:"center",
                    width:500
                },
            }}>
                    {icon}
                <H3>
                    {title}
                </H3>
            </Group>
            <Text ml={80}
                sx={(theme) => ({
                    [theme.fn.smallerThan('sm')]: {
                        marginLeft: 0,
                    },
                })}>
                {children}
            </Text>
            {
                link && (
                    <Anchor
                        className={anchorClass}
                        component={Link}
                        to={link.url}
                        weight={700}
                        color={theme.colors.pink[5]}
                        ml={80}
                        sx={(theme) => ({
                            position: 'relative',

                            '&:after': {
                                content: '">"',
                                position: 'absolute',
                                right: -27,
                                transition: 'all 0.2s ease-out',
                            },

                            '&:hover': {
                                '&:after': {
                                    right: -37,
                                },
                            },

                            [theme.fn.smallerThan('sm')]: {
                                marginLeft: 0,
                            },

                        })}>
                        {link.label}
                    </Anchor>
                )
            }
        </Flex>
    );
};

export default FeatureSection;
