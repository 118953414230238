import * as React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { getFeaturedClients } from '../../services/clients/featured-clients';
import { getRandomQuote } from '../../services/quotes/random-quote';
import { renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

//types
import type { ClientType, QuoteType } from '../../types';
import type { PropsWithChildren } from 'react';

//components
import { Button, Container, Group, SimpleGrid, Text, useMantineTheme, MediaQuery, Flex, createStyles, MantineTheme, Grid, Center } from '@mantine/core';
import TextSection from '../sections/text-section';
import { Link } from 'gatsby';
import { FaChevronRight } from 'react-icons/fa';
import H2 from '../typography/h2';
import TextDivider from '../ui/text-divider';
import { GatsbyImage } from 'gatsby-plugin-image';
import Quote from '../typography/quote';

// illustrations
import achievementSVG from '../../images/illustrations/achievement.svg';
import { useMediaQuery } from '@mantine/hooks';

{/* add styling */ }
const useStyles = createStyles((theme: MantineTheme) => ({

    title: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(38px + 1vw)",
            maxHeight: 768,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 7,
        },
    },

    textQuote: {
        maxWidth: 720,
        [theme.fn.smallerThan('md')]: {
            textAlign: 'center',
            display: 'flex',
            fontSize: "calc(32px + 1vw)",
            lineHeight: 1.5,
        }
    },

    groupQuote: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    },

    imageContainer: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.spacing.xl,
            paddingBottom: theme.spacing.xl
        }
    },

    buttonMdScreen: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(28px + 1vw)",
            display: 'block',
            paddingTop: theme.spacing.lg,
            marginBottom: theme.spacing.lg
        },
        [theme.fn.largerThan('md')]: {
            display: 'none'
        }
    },

    buttonLgScreen: {
        [theme.fn.smallerThan('md')]: {
            display: 'none'
        }
    },

    brandLogo: {
        maxWidth: 120,
        maxHeight: 90,
        [theme.fn.smallerThan('md')]: {
            maxWidth: 180,
            maxHeight: 100,
        }
    }

}));

const ClientsSection = () => {
    const clients: ClientType[] = getFeaturedClients();
    const quote: QuoteType = getRandomQuote();
    const theme = useMantineTheme();
    const { classes } = useStyles();

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (_node: any, children: any) =>
                <>{children}</>
        },
    };

    const renderContentfulParagraph = (content: RenderRichTextData<any>) => {
        return renderRichText(content, options);
    };

    const Logo = ({ children }: PropsWithChildren) => (
        <Container
            px={0}
            sx={{
                width: '100%',
                height: 128,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.colors.gray[2],
                borderRadius: 10,
                transition: 'all 0.2s linear',

                img: {
                    filter: 'grayscale(1) contrast(0.7)',
                },

                '&:hover': {
                    transform: 'scale(1.1)',
                }
            }}>
            {children}
        </Container>
    );


    return (
        <TextSection>
            <H2 className={classes.title}>
                Stuk voor stuk tevreden klanten
            </H2>
            <TextDivider />

            <SimpleGrid
                mt={70}
                mb={70}
                cols={6}
                spacing={15}
                breakpoints={[
                    { maxWidth: 'md', cols: 2, spacing: 'xs' },
                ]}>
                {
                    clients.map((client, index) => (
                        <Logo key={index}>
                            <GatsbyImage
                                alt={client.name}
                                image={client.logo.gatsbyImageData}
                                className={classes.brandLogo}

                                imgStyle={{
                                    objectFit: 'contain',
                                }} />
                        </Logo>
                    ))
                }

            </SimpleGrid>

            <Center>
                <Button className={classes.buttonMdScreen} component={Link} to='/projecten' >
                    <Group spacing={15}>
                        Ontdek ons werk
                        <FaChevronRight />
                    </Group>
                </Button>
            </Center>


            <MediaQuery largerThan={'md'} styles={{ display: 'none' }}>
                <img className={classes.imageContainer}
                    src={achievementSVG}
                    alt=''
                    width={600} />
            </MediaQuery>

            <Group noWrap align='center' position='apart' className={classes.groupQuote}>

                <Flex direction="column"
                    justify={{ base: 'center', md: 'flex-start' }}
                    align={{ base: 'center', md: 'flex-start' }}>

                    <Quote mb={25} className={classes.textQuote}>
                        {renderContentfulParagraph(quote.content)}
                    </Quote>

                    <Text weight={800} className={classes.textQuote}>
                        {
                            quote.author
                        }
                    </Text>
                    <Text className={classes.textQuote}>
                        {
                            renderContentfulParagraph(quote.authorDescription)
                        }
                    </Text>
                    <Button mt={60} component={Link} to='/projecten' className={classes.buttonLgScreen}>
                        <Group spacing={15}>
                            Ontdek ons werk
                            <FaChevronRight />
                        </Group>
                    </Button>
                </Flex>
                <MediaQuery smallerThan={'md'} styles={{ display: 'none' }}>
                    <img
                        src={achievementSVG}
                        alt=''
                        width={405} />
                </MediaQuery>
            </Group>
        </TextSection>
    );
};

export default ClientsSection;
